import React from "react"

const HwcLesson = ({lesson}) => {
    var url = `https://hwc.brandeis.de/exercise?lesson=${lesson}&viewname=IframeCourse`

  return (
      <section >
      <iframe src={url} title="HWC Lesson" width="100%" height="600"></iframe>
      
      </section>
  )
}
export default  HwcLesson

