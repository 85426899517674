import * as React from "react";

import { StaticImage } from "gatsby-plugin-image";

import HwcLesson from "../components/hwcLesson";
import Layout from "../components/layout";
import Link from "../components/localizedLink";

import * as LayoutStyles from "../styles/layout.module.scss";

const ELearning = ({ pageContext }) => {
  var locale = pageContext.locale;

  var translationContext = {
    locale: 'de',
    defaultMenu: false
  };

  return (
    <Layout title={"E-Learning"} pageContext={pageContext}
      translationContext={translationContext}>
      <div class={LayoutStyles.singleTopicLayout}>
        <main>
          <h1>E-Learning Kurse</h1>
          <p>Wir bieten mit den bewährten Inhalten auch E-Learning Kurse an. Bitte nehmen Sie bei Intresse Kontakt mit uns auf.

          </p>
          <div class={LayoutStyles.tileGrid}>
            <Link
              to="https://brandeis-training.de/courses/hana-sql-fuer-anfaenger/"
              locale={locale}
            >
              <div class={LayoutStyles.tile}>
                <div class={LayoutStyles.centered}>
                  <StaticImage
                    src="../images/Cover_SQL_fuer_Anfaenger_300.jpg"
                    alt="Brandeis Consulting Logo"
                    width={300}
                  ></StaticImage>
                  <h3>Kostenloser Kurs</h3>
                  <h2>HANA SQL für Anfänger</h2>
                </div>
                <p>
                  Die perfekte Vorbereitung für die anderen Kurse. Egal ob als
                  Auffrischung oder zum neu lernen. 

                </p>
                  <p><b>Dieser Kurs läuft noch auf unserer alten Plattform</b></p>
              </div>
            </Link>
            <Link
              to="../contact"
              locale={locale}
            >
              <div class={LayoutStyles.tile}>
                <div class={LayoutStyles.centered}>
                  <StaticImage
                    src="../images/Cover_SQLScript-Grundlagen_300.png"
                    alt="Brandeis Consulting Logo"
                    width={300}
                  ></StaticImage>
                  <h3>E-Learning Kurs</h3>
                  <h2>SQLScript Grundlagen</h2>
                </div>
                <p>
                  Der Einstieg in SQLScript. Dieser Kurs bildet das Fundament
                  für die Arbeit mit SQL auf der SAP HANA Datenbank.{" "}
                </p>
                <p><b>€450,00 Zzgl. MwSt</b></p>
                <p>Bei Interesse melden Sie sich bitte über unser <a href="../contact" style={{textDecoration: 'underline',
                                                                                                fontWeight: 600 }}>Kontaktformular</a>.</p>
              </div>
            </Link>
            <Link
              to="../contact"
              locale={locale}
            >
              <div class={LayoutStyles.tile}>
                <div class={LayoutStyles.centered}>
                  <StaticImage
                    src="../images/Cover_SQLScript-BW_900-1.png"
                    alt="E-Learning SQLScript für BW-Berater"
                    width={300}
                  ></StaticImage>
                  <h3>E-Learning Kurs</h3>
                  <h2>SQLScript für BW-Berater</h2>
                </div>
                <p>
                  Der Aufbaukurs mit dem Fokus SAP BW Transformationsroutinen.
                  Mit vielen praktischen Beispielen und Übungen.
                </p>
                <p><b>€450,00 Zzgl. MwSt</b></p>
                <p>Bei Interesse melden Sie sich bitte über unser <a href="../contact" style={{textDecoration: 'underline',
                                                                                                fontWeight: 600 }}>Kontaktformular</a>.</p>
              </div>
            </Link>
          </div>
          <p></p>
          <h2>Die HANA Web Console</h2>
          <p>
            Die Selbstlernkurse werden durch unsere HANA Web Console (HWC),eine
            interaktive SQL-Konsole im Webbrowser unterstützt. Das verwendete
            Demo Datenmodell ist bereits installiert und es können ohne jegliche
            Vorbereitung dirkt Übungen im Browser gemacht werden.
          </p>
          <HwcLesson lesson={1} />
        </main>
      </div>
    </Layout>
  );
};

export default ELearning;
